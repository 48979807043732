import * as React from "react";
import { dataLayerGTM } from "./functions/dataLayerGTM";

export const useOnClickOutside = (ref, handler) => {
  React.useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export const useCountDown = countDownDate => {
  const [timeCount, setTimeCount] = React.useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  React.useEffect(() => {
    let interval;

    if (timeCount.days !== undefined) {
      interval = setInterval(() => {
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        if (timeCount.seconds < 0) {
          setTimeCount({
            days: undefined,
            hours: undefined,
            minutes: undefined,
            seconds: undefined,
          });
        } else {
          setTimeCount({ days, hours, minutes, seconds });
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timeCount]);

  return timeCount;
};

/**
 *
 * @return {{status: Status, handleCheckEcdd: (params: HandleCheck) => () => void, onResetStatus: () => void, onSetStatus: (status: Status) => void}}
 */
export const useEcddChecking = ({
  ecddStatus = {},
  aml,
  shouldNotPromptOnEffect = false,
}) => {
  /**
   * @typedef {null | "pending" | "rejected" | "expired"} Status
   */

  /**
   * @typedef {{ cb?: () => void, ecddExpiredCb?: () => void, gtm?: {event: string, action: string, category: string, label: string} }} HandleCheck
   */

  /**
   * @type {[Status, React.Dispatch<Status>]}
   *
   */
  const [status, setStatus] = React.useState(null);
  const ecddStatusRef = React.useRef({});

  const handleCheckEcdd = ({ cb, ecddExpiredCb, gtm }) => () => {
    if (gtm && typeof gtm === "object" && Object.values(gtm).length > 0) {
      dataLayerGTM(gtm.event, gtm.action, gtm.category, gtm.label);
    }

    if (aml && typeof aml !== "object") {
      aml = {};
    }

    const isAmlResultHigh = aml.result === "3";
    const isAmlStatusEcddPending = aml.status === "4";
    const isAmlStatusNotPassed = aml.status === "3";

    const isEcddExpired =
      "isExpired" in ecddStatusRef.current && ecddStatusRef.current.isExpired;

    const isEcddBlank =
      "isBlank" in ecddStatusRef.current && ecddStatusRef.current.isBlank;

    if (ecddStatusRef.current.ecddSts === "1") {
      setStatus("pending");
      return;
    }

    if (ecddStatusRef.current.ecddSts === "3") {
      setStatus("rejected");
      return;
    }

    if ((isAmlStatusNotPassed && isEcddBlank) || (isEcddExpired && isAmlResultHigh)) {
      ecddExpiredCb && ecddExpiredCb();
      return;
    }

    if (cb && typeof cb === "function") {
      cb();
    }
  };

  const onResetStatus = () => {
    setStatus(null);
  };

  /**
   *
   * @param {Status} sts
   * @returns void
   */
  const onSetStatus = sts => setStatus(sts);

  React.useEffect(() => {
    if (aml && typeof aml === "object" && ecddStatus) {
      // means ecdd is blank
      // so we treated it like expired
      // so that user will redirect to ecdd form

      if (
        (typeof ecddStatus === "string" && ecddStatus.includes("OK")) ||
        (typeof ecddStatus === "object" && ecddStatus.ecddSts === "5")
      ) {
        ecddStatus = { isBlank: true };
      }

      ecddStatusRef.current = ecddStatus;

      if (shouldNotPromptOnEffect) return;

      if (typeof ecddStatus !== "object") {
        // prevent null reference
        ecddStatus = {};
      }

      const { ecddSts } = ecddStatus;

      if (ecddSts === "1") {
        onSetStatus("pending");
        return;
      }

      if (ecddSts === "3") {
        onSetStatus("rejected");
        return;
      }
    }
  }, [ecddStatus]);

  return { status, handleCheckEcdd, onResetStatus, onSetStatus };
};

export const useHandleDuplicate = (updateCustomer, getNewTokenSuccess, cb) => {
  const copywriting = {
    mp: {
      heading: "MyKad ID Conflict",
      title: "Thank you for your interest in GO-Invest.",
      description:
        "It appears that someone else has already signed up using the same ID as yours. This issue could have occurred due to either you or the other user capturing the wrong MyKad ID during the EKYC process.",
      additionalContext:
        "Don’t worry, please contact our customer service for assistance.",
    },
    tng: {
      heading: "Personal info verification",
      title:
        "Thank you for your interest in GO Invest. We have noticed that you recently updated your profile information.",
      description:
        " To ensure a seamless investment experience, particularly for Cash In and Cash Out transactions, please confirm if the following details are correct.",
      additionalContext:
        "If any of the details are incorrect, please contact Touch 'n Go customer service for assistance.",
    },
  };

  const [duplicated, setDuplicated] = React.useState({
    masterId: "",
    name: "",
    idNo: "",
    mobileNo: "",
    ekycMobileNo: "",
    idTypeCd: "",
    type: "mp",
  });
  const [token, setToken] = React.useState(null);
  const submitted = React.useRef(false);

  const handleDuplicate = () => {
    if (submitted.current) return;

    submitted.current = true;

    if (duplicated.type === "mp") {
      window.my.call("openAppLink", {
        url: "https://wa.me/60162999792",
      });

      return;
    }

    updateCustomer(
      {
        masterId: duplicated.masterId,
        idNo: duplicated.idNo,
        idTypeCd: duplicated.idTypeCd,
        name: duplicated.name,
        mobileNo: duplicated.ekycMobileNo,
      },
      () => {
        window.eventFire("CUSTOMER_SET_STATUS_ROOT", token);
        window.eventManager.fire("CUSTOMER_SET_STATUS");
        if (cb && typeof cb === "function") {
          cb();
        }

        setDuplicated({
          masterId: "",
          name: "",
          idNo: "",
          mobileNo: "",
          idTypeCd: "",
          ekycMobileNo: "",
          type: "tng",
        });
      }
    );
  };

  React.useEffect(() => {
    window.eventManager.on("DUPLICATE_CUSTOMER_VALIDATION", data => {
      const { delta, ...customerInfo } = data.data;
      getNewTokenSuccess(data.bearer);
      setToken(data.bearer);
      setDuplicated({
        name: delta.name,
        idNo: delta.idNo,
        mobileNo: delta.mobileNo,
        idTypeCd: delta.idTypeCd,
        masterId: customerInfo.masterId,
        type: delta.isDisableConfirm ? "mp" : "tng",
      });

      const { fullName, identities, mobileNo, ...rest } = customerInfo;

      // replace necessary value
      localStorage.setItem(
        "mpUserInfo",
        JSON.stringify({
          ...rest,
          fullName: "name" in delta ? delta.name : fullName,
          mobileNo: "mobileNo" in delta ? delta.mobileNo : mobileNo,
          identities:
            "idNo" in delta
              ? identities.map(({ idNo, ...identity }) => ({
                  ...identity,
                  idNo: delta.idNo,
                }))
              : identities,
        })
      );
    });

    window.eventManager.on(
      "EKYC_REVALIDATION",
      ({ delta = {}, masterId, mobileNo }) => {
        setDuplicated(prev => ({
          ...prev,
          mobileNo: delta.mobileNo,
          ekycMobileNo: mobileNo,
          type: delta.isDisableConfirm ? "mp" : "tng",
          masterId,
        }));
      }
    );

    return () => window.eventManager.clearEvents();
  }, []);

  return {
    handleDuplicate,
    duplicated,
    copywriting: copywriting[duplicated.type],
  };
};

export const useGiveawayAlert = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  const lastNotifyGiveaway =
    localStorage.getItem("lastNotifyGiveaway") || date.toDateString();
  const [notifyGiveaway, setNotifyGiveaway] = React.useState(
    // new Date(lastNotifyGiveaway).getDate() === new Date().getDate()
    //   ? false
    //   : true
    false
  );

  return {
    notifyGiveaway,
    setNotifyGiveaway,
  };
};
